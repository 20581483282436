import Types from './Types';
import { combineReducers } from 'redux';

const patientReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.FETCH_PATIENT_SUCCESS:
      return payload.patient;
    case Types.REFRESH_PATIENT_STATISTICS_SUCCESS:
      return { patient: state.patient, order: payload.order[0] };
    case Types.CLEAR_PATIENT:
    case Types.DELETE_PATIENT_SUCCESS:
      return null;
    default:
      return state;
  }
};

const successPostReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Types.POST_PATIENT_SUCCESS:
      // case Types.EDIT_PATIENT_SUCCESS:
      return true;
    case Types.FETCH_PATIENT_SUCCESS:
    case Types.EDIT_PATIENT_SUCCESS:
    case Types.FETCH_PATIENT_ERROR:
    case Types.POST_PATIENT_ERROR:
    case Types.EDIT_PATIENT_ERROR:
    case Types.CLEAR_PATIENT:
      return false;
    default:
      return state;
  }
};

const successDeleteReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Types.DELETE_PATIENT_SUCCESS:
      return true;
    case Types.FETCH_PATIENT_SUCCESS:
    case Types.POST_PATIENT_SUCCESS:
    case Types.EDIT_PATIENT_SUCCESS:
    case Types.FETCH_PATIENT_ERROR:
    case Types.POST_PATIENT_ERROR:
    case Types.EDIT_PATIENT_ERROR:
    case Types.DELETE_PATIENT_ERROR:
      return false;
    default:
      return state;
  }
};

const refreshStatisticsSuccessReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Types.REFRESH_ALL_PATIENTS_STATISTICS_SUCCESS:
      return true;
    case Types.REFRESH_ALL_PATIENTS_STATISTICS_ERROR:
    case Types.CLEAR_REFRESH_ALL_PATIENTS_STATISTICS:
      return false;
    default:
      return state;
  }
};

const loadingReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Types.FETCH_PATIENT_START:
    case Types.POST_PATIENT_START:
    case Types.EDIT_PATIENT_START:
      return true;
    case Types.FETCH_PATIENT_SUCCESS:
    case Types.POST_PATIENT_SUCCESS:
    case Types.EDIT_PATIENT_SUCCESS:
    case Types.FETCH_PATIENT_ERROR:
    case Types.POST_PATIENT_ERROR:
    case Types.EDIT_PATIENT_ERROR:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.FETCH_PATIENT_START:
    case Types.POST_PATIENT_START:
    case Types.EDIT_PATIENT_START:
    case Types.DELETE_PATIENT_START:
    case Types.REFRESH_ALL_PATIENTS_STATISTICS_START:
      return null;
    case Types.FETCH_PATIENT_ERROR:
    case Types.POST_PATIENT_ERROR:
    case Types.EDIT_PATIENT_ERROR:
    case Types.DELETE_PATIENT_ERROR:
    case Types.REFRESH_ALL_PATIENTS_STATISTICS_ERROR:
      return payload.error;
    default:
      return state;
  }
};

export default combineReducers({
  patient: patientReducer,
  loading: loadingReducer,
  error: errorReducer,
  successPost: successPostReducer,
  successDelete: successDeleteReducer,
  refreshStatisticsSuccess: refreshStatisticsSuccessReducer,
});
