import Types from './Types';
import { combineReducers } from 'redux';

const pharmaciesReducer = (state = [], { type, payload }) => {
  switch (type) {
    case Types.FETCH_PHARMACIES_SUCCESS:
      return payload.pharmacies;
    case Types.FETCH_PHARMACIES_ERROR:
    case Types.CLEAR_PHARMACIES:
      return [];
    default:
      return state;
  }
};

const customPharmaciesReducer = (state = [], { type, payload }) => {
  switch (type) {
    case Types.FETCH_CUSTOM_PHARMACIES_SUCCESS:
      return payload.pharmacies;
    case Types.FETCH_CUSTOM_PHARMACIES_ERROR:
    case Types.CLEAR_PHARMACIES:
      return [];
    default:
      return state;
  }
};

const pharmacyReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.FETCH_PHARMACY_SUCCESS:
    case Types.POST_PHARMACY_SUCCESS:
      return payload.pharmacy;
    case Types.FETCH_PHARMACY_ERROR:
    case Types.POST_PHARMACY_ERROR:
    case Types.CLEAR_PHARMACY:
      return null;
    default:
      return state;
  }
};

const loadingReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Types.FETCH_PHARMACIES_START:
    case Types.FETCH_PHARMACY_START:
    case Types.GENERATE_PDF_START:
      return true;
    case Types.FETCH_PHARMACIES_SUCCESS:
    case Types.FETCH_PHARMACY_SUCCESS:
    case Types.GENERATE_PDF_SUCCESS:
    case Types.FETCH_PHARMACIES_ERROR:
    case Types.FETCH_PHARMACY_ERROR:
    case Types.GENERATE_PDF_ERROR:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.FETCH_PHARMACIES_START:
    case Types.FETCH_PHARMACY_START:
    case Types.POST_PHARMACY_START:
    case Types.GENERATE_PDF_START:
      return null;
    case Types.FETCH_PHARMACIES_ERROR:
    case Types.FETCH_PHARMACY_ERROR:
    case Types.POST_PHARMACY_ERROR:
    case Types.GENERATE_PDF_ERROR:
      return payload.error;
    default:
      return state;
  }
};

export default combineReducers({
  pharmacy: pharmacyReducer,
  pharmacies: pharmaciesReducer,
  customPharmacies: customPharmaciesReducer,
  loading: loadingReducer,
  error: errorReducer,
});
