const Types = {
  FETCH_SENT_ORDERS_START: 'orders/FETCH_SENT_ORDERS_START',
  FETCH_SENT_ORDERS_SUCCESS: 'orders/FETCH_SENT_ORDERS_SUCCESS',
  FETCH_SENT_ORDERS_ERROR: 'orders/FETCH_SENT_ORDERS_ERROR',
  FETCH_EXPIRED_ORDERS_START: 'orders/FETCH_EXPIRED_ORDERS_START',
  FETCH_EXPIRED_ORDERS_SUCCESS: 'orders/FETCH_EXPIRED_ORDERS_SUCCESS',
  FETCH_EXPIRED_ORDERS_ERROR: 'orders/FETCH_EXPIRED_ORDERS_ERROR',
  FETCH_PENDING_ORDERS_START: 'orders/FETCH_PENDING_ORDERS_START',
  FETCH_PENDING_ORDERS_SUCCESS: 'orders/FETCH_PENDING_ORDERS_SUCCESS',
  FETCH_PENDING_ORDERS_ERROR: 'orders/FETCH_PENDING_ORDERS_ERROR',
  FETCH_PENDING_ORDERS_PHARMACY_START:
    'orders/FETCH_PENDING_ORDERS_PHARMACY_START',
  FETCH_PENDING_ORDERS_PHARMACY_SUCCESS:
    'orders/FETCH_PENDING_ORDERS_PHARMACY_SUCCESS',
  FETCH_PENDING_ORDERS_PHARMACY_ERROR:
    'orders/FETCH_PENDING_ORDERS_PHARMACY_ERROR',
  EDIT_ORDER_START: 'orders/EDIT_ORDER_START',
  EDIT_ORDER_SUCCESS: 'orders/EDIT_ORDER_SUCCESS',
  EDIT_ORDER_ERROR: 'orders/EDIT_ORDER_ERROR',
  SET_PENDING_DONE_START: 'orders/SET_PENDING_DONE_START',
  SET_PENDING_DONE_SUCCESS: 'orders/SET_PENDING_DONE_SUCCESS',
  SET_PENDING_DONE_ERROR: 'orders/SET_PENDING_DONE_ERROR',
  CLEAR_ORDERS: 'orders/CLEAR_ORDERS',

  ADD_NOTE_START: 'orders/ADD_NOTE_START',
  ADD_NOTE_SUCCESS: 'orders/ADD_NOTE_SUCCESS',
  ADD_NOTE_ERROR: 'orders/ADD_NOTE_ERROR',
  DELETE_NOTE_START: 'orders/DELETE_NOTE_START',
  DELETE_NOTE_SUCCESS: 'orders/DELETE_NOTE_SUCCESS',
  DELETE_NOTE_ERROR: 'orders/DELETE_NOTE_ERROR',

  ADD_NOTE_PATIENT_START: 'orders/ADD_NOTE_PATIENT_START',
  ADD_NOTE_PATIENT_SUCCESS: 'orders/ADD_NOTE_PATIENT_SUCCESS',
  ADD_NOTE_PATIENT_ERROR: 'orders/ADD_NOTE_PATIENT_ERROR',
  DELETE_NOTE_PATIENT_START: 'orders/DELETE_NOTE_PATIENT_START',
  DELETE_NOTE_PATIENT_SUCCESS: 'orders/DELETE_NOTE_PATIENT_SUCCESS',
  DELETE_NOTE_PATIENT_ERROR: 'orders/DELETE_NOTE_PATIENT_ERROR',
};

export default Types;
