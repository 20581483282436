const Types = {
  FETCH_PHARMACIES_START: 'pharmacy/FETCH_PHARMACIES_START',
  FETCH_PHARMACIES_SUCCESS: 'pharmacy/FETCH_PHARMACIES_SUCCESS',
  FETCH_PHARMACIES_ERROR: 'pharmacy/FETCH_PHARMACIES_ERROR',
  FETCH_CUSTOM_PHARMACIES_START: 'pharmacy/FETCH_CUSTOM_PHARMACIES_START',
  FETCH_CUSTOM_PHARMACIES_SUCCESS: 'pharmacy/FETCH_CUSTOM_PHARMACIES_SUCCESS',
  FETCH_CUSTOM_PHARMACIES_ERROR: 'pharmacy/FETCH_CUSTOM_PHARMACIES_ERROR',
  CLEAR_PHARMACIES: 'pharmacy/CLEAR_PHARMACIES',
  FETCH_PHARMACY_START: 'pharmacy/FETCH_PHARMACY_START',
  FETCH_PHARMACY_SUCCESS: 'pharmacy/FETCH_PHARMACY_SUCCESS',
  FETCH_PHARMACY_ERROR: 'pharmacy/FETCH_PHARMACY_ERROR',
  POST_PHARMACY_START: 'pharmacy/POST_PHARMACY_START',
  POST_PHARMACY_SUCCESS: 'pharmacy/POST_PHARMACY_SUCCESS',
  POST_PHARMACY_ERROR: 'pharmacy/POST_PHARMACY_ERROR',
  CLEAR_PHARMACY: 'pharmacy/CLEAR_PHARMACY',

  GENERATE_PDF_START: 'pharmacy/GENERATE_PDF_START',
  GENERATE_PDF_SUCCESS: 'pharmacy/GENERATE_PDF_SUCCESS',
  GENERATE_PDF_ERROR: 'pharmacy/GENERATE_PDF_ERROR',
};

export default Types;
