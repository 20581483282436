import React from 'react';
import { Route } from 'react-router-dom';
import AppContainer from './AppContainer';
import ScrollToTop from './js/utils/ScrollToTop';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import { AuthProvider } from './js/Auth';

const App = () => {
  return (
    <>
      <AuthProvider>
        <ScrollToTop />
        <ReactNotification />
        <Route>
          <AppContainer />
        </Route>
      </AuthProvider>
    </>
  );
};

export default App;
