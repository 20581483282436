import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

import patientsReducer from './patients/patientsReducer';
import patientReducer from './patient/patientReducer';
import sessionReducer from './session/sessionReducer';
import pharmacyReducer from './pharmacy/pharmacyReducer';
import clinicsReducer from './clinics/clinicsReducer';
import medicinesReducer from './medicines/medicinesReducer';
import ordersReducer from './orders/ordersReducer';

const rootReducer = combineReducers({
  patients: patientsReducer,
  patient: patientReducer,
  session: sessionReducer,
  pharmacy: pharmacyReducer,
  clinics: clinicsReducer,
  medicines: medicinesReducer,
  orders: ordersReducer,
});

const enhancer = applyMiddleware(ReduxThunk);

const store = createStore(rootReducer, composeWithDevTools(enhancer));

export default store;
