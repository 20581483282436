const Types = {
  FETCH_MEDICINES_START: 'medicines/FETCH_MEDICINES_START',
  FETCH_MEDICINES_SUCCESS: 'medicines/FETCH_MEDICINES_SUCCESS',
  FETCH_MEDICINES_ERROR: 'medicines/FETCH_MEDICINES_ERROR',
  POST_MEDICIN_START: 'medicines/POST_MEDICIN_START',
  POST_MEDICIN_SUCCESS: 'medicines/POST_MEDICIN_SUCCESS',
  POST_MEDICIN_ERROR: 'medicines/POST_MEDICIN_ERROR',
  EDIT_MEDICIN_START: 'medicines/EDIT_MEDICIN_START',
  EDIT_MEDICIN_SUCCESS: 'medicines/EDIT_MEDICIN_SUCCESS',
  EDIT_MEDICIN_ERROR: 'medicines/EDIT_MEDICIN_ERROR',
  DELETE_MEDICIN_START: 'medicines/DELETE_MEDICIN_START',
  DELETE_MEDICIN_SUCCESS: 'medicines/DELETE_MEDICIN_SUCCESS',
  DELETE_MEDICIN_ERROR: 'medicines/DELETE_MEDICIN_ERROR',
};

export default Types;
