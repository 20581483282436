import Types from './Types';
import { combineReducers } from 'redux';

const medicinesReducer = (state = [], { type, payload }) => {
  switch (type) {
    case Types.FETCH_MEDICINES_SUCCESS:
      return payload.medicines;
    case Types.POST_MEDICIN_SUCCESS:
      let newMedicines = [];
      if (payload.medicin[0] && payload.medicin[1]) {
        newMedicines = [payload.medicin[0], payload.medicin[1]];
      } else if (payload.medicin[0]) {
        newMedicines = [payload.medicin[0]];
      } else if (payload.medicin[1]) {
        newMedicines = [payload.medicin[1]];
      }
      if (state.length !== 0) {
        return [...state, ...newMedicines];
      } else {
        return [...newMedicines];
      }
    case Types.EDIT_MEDICIN_SUCCESS:
      return state.map(med =>
        med._id === payload.medicin._id ? payload.medicin : med,
      );
    case Types.DELETE_MEDICIN_SUCCESS:
      return state.filter(medicin => medicin._id !== payload.id);
    default:
      return state;
  }
};

const loadingReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Types.FETCH_MEDICINES_START:
      return true;
    case Types.FETCH_MEDICINES_SUCCESS:
    case Types.FETCH_MEDICINES_ERROR:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.FETCH_MEDICINES_START:
    case Types.POST_MEDICIN_START:
    case Types.EDIT_MEDICIN_START:
    case Types.DELETE_MEDICIN_START:
      return null;
    case Types.FETCH_MEDICINES_ERROR:
    case Types.POST_MEDICIN_ERROR:
    case Types.EDIT_MEDICIN_ERROR:
    case Types.DELETE_MEDICIN_ERROR:
      return payload.error;
    default:
      return state;
  }
};

export default combineReducers({
  medicines: medicinesReducer,
  loading: loadingReducer,
  error: errorReducer,
});
