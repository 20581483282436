import {
  registerRequest,
  registerSuccess,
  registerError,
  authMeRequest,
  authMeSuccess,
  authMeError,
  editUserRequest,
  editUserSuccess,
  editUserError,
  validateTokenRequest,
  validateTokenSuccess,
  validateTokenError,
  fetchTodayStatusStart,
  fetchTodayStatusSuccess,
  fetchTodayStatusError,
  importPatientsStart,
  importPatientsSuccess,
  importPatientsError,
} from './sessionActions';

import apiClinicsInstance from '../../utils/api/apiClinicsInstance';
import apiOrdersInstance from '../../utils/api/apiOrdersInstance';
import { store } from 'react-notifications-component';

// VALIDATE TOKEN

export const validateToken = token => dispatch => {
  dispatch(validateTokenRequest());
  apiClinicsInstance
    .get(`/verify/${token}`)
    .then(response => {
      dispatch(validateTokenSuccess(response.data.result));
    })
    .catch(error => {
      dispatch(validateTokenError(error));
    });
};

// REGISTRATION

export const registration = (token, credentials) => dispatch => {
  dispatch(registerRequest());

  apiClinicsInstance
    .post(`/verify/${token}`, credentials)
    .then(response => {
      dispatch(registerSuccess(response.data.result));
    })
    .catch(error => {
      dispatch(registerError(error));
    });
};

// AUTH ME

export const authMe = () => dispatch => {
  dispatch(authMeRequest());

  apiClinicsInstance
    .get('/me')
    .then(response => {
      dispatch(authMeSuccess(response.data));
    })
    .catch(error => {
      dispatch(authMeError(error));
    });
};

// EDIT USER

export const editUser = credentials => dispatch => {
  dispatch(editUserRequest());

  apiClinicsInstance
    .post(`/edit/${credentials._id}`, credentials)
    .then(response => {
      dispatch(editUserSuccess(response.data.result));
    })
    .catch(error => {
      dispatch(editUserError(error));
    });
};

// TODAY STATUS

export const fetchTodayStatus = () => dispatch => {
  dispatch(fetchTodayStatusStart());
  apiClinicsInstance
    .get('/todayStatus')
    .then(response => {
      dispatch(fetchTodayStatusSuccess(response.data.result));
    })
    .catch(error => {
      dispatch(fetchTodayStatusError(error));
    });
};

// IMPORT PATIENTS

export const importPatients = formData => dispatch => {
  dispatch(importPatientsStart());
  apiClinicsInstance
    .post('/import', formData)
    .then(response => {
      dispatch(importPatientsSuccess());
      store.addNotification({
        title: 'Great!',
        message: 'Patients were imported successfully',
        type: 'success',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 4000,
        },
        slidingEnter: {
          duration: 800,
          timingFunction: 'ease-out',
          delay: 0,
        },
        slidingExit: {
          duration: 800,
          timingFunction: 'ease-out',
          delay: 0,
        },
      });
    })
    .catch(error => {
      dispatch(importPatientsError(error));
      store.addNotification({
        title: 'Oops!',
        message: error.response.data.result.error,
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 10000,
        },
        slidingEnter: {
          duration: 800,
          timingFunction: 'ease-out',
          delay: 0,
        },
        slidingExit: {
          duration: 800,
          timingFunction: 'ease-out',
          delay: 0,
        },
      });
    });
};
