const Types = {
  FETCH_CLINICS_START: 'clinics/FETCH_CLINICS_START',
  FETCH_CLINICS_SUCCESS: 'clinics/FETCH_CLINICS_SUCCESS',
  FETCH_CLINICS_ERROR: 'clinics/FETCH_CLINICS_ERROR',
  FETCH_CLINIC_START: 'clinics/FETCH_CLINIC_START',
  FETCH_CLINIC_SUCCESS: 'clinics/FETCH_CLINIC_SUCCESS',
  FETCH_CLINIC_ERROR: 'clinics/FETCH_CLINIC_ERROR',
  POST_CLINIC_START: 'clinics/POST_CLINIC_START',
  POST_CLINIC_SUCCESS: 'clinics/POST_CLINIC_SUCCESS',
  POST_CLINIC_ERROR: 'clinics/POST_CLINIC_ERROR',
  EDIT_CLINIC_START: 'clinics/EDIT_CLINIC_START',
  EDIT_CLINIC_SUCCESS: 'clinics/EDIT_CLINIC_SUCCESS',
  EDIT_CLINIC_ERROR: 'clinics/EDIT_CLINIC_ERROR',
  DELETE_CLINIC_START: 'clinics/DELETE_CLINIC_START',
  DELETE_CLINIC_SUCCESS: 'clinics/DELETE_CLINIC_SUCCESS',
  DELETE_CLINIC_ERROR: 'clinics/DELETE_CLINIC_ERROR',
  CLEAR_CLINIC: 'clinics/CLEAR_CLINIC',
};

export default Types;
