import axios from 'axios';
import app from '../../../base';

const apiOrdersInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:5000/api/order'
      : '/api/order',
});

const setInterceptorsToApiInstance = async api => {
  try {
    app.auth().onAuthStateChanged(async user => {
      if (user) {
        api.interceptors.request.use(
          async config => {
            const idToken = await app.auth().currentUser.getIdToken(true);
            config.headers['Authorization'] = `Bearer ${idToken}`;
            config.headers['Content-Type'] = 'application/json';
            return config;
          },
          error => {
            Promise.reject(error);
          },
        );
      }
    });
  } catch (error) {
    console.log(error);
    // alert(error);
  }
};

setInterceptorsToApiInstance(apiOrdersInstance);

export default apiOrdersInstance;
