const Types = {
  AUTH_ME_REQUEST: 'session/AUTH_ME_REQUEST',
  AUTH_ME_SUCCESS: 'session/AUTH_ME_SUCCESS',
  AUTH_ME_ERROR: 'session/AUTH_ME_ERROR',
  EDIT_USER_REQUEST: 'session/EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS: 'session/EDIT_USER_SUCCESS',
  EDIT_USER_ERROR: 'session/EDIT_USER_ERROR',
  REGISTER_REQUEST: 'session/REGISTER_REQUEST',
  REGISTER_SUCCESS: 'session/REGISTER_SUCCESS',
  REGISTER_ERROR: 'session/REGISTER_ERROR',
  LOGOUT: 'session/LOGOUT',
  CLEAR_USER: 'session/CLEAR_USER',
  VALIDATE_TOKEN_REQUEST: 'session/VALIDATE_TOKEN_REQUEST',
  VALIDATE_TOKEN_SUCCESS: 'session/VALIDATE_TOKEN_SUCCESS',
  VALIDATE_TOKEN_ERROR: 'session/VALIDATE_TOKEN_ERROR',
  FETCH_TODAY_STATUS_START: 'session/FETCH_TODAY_STATUS_START',
  FETCH_TODAY_STATUS_SUCCESS: 'session/FETCH_TODAY_STATUS_SUCCESS',
  FETCH_TODAY_STATUS_ERROR: 'session/FETCH_TODAY_STATUS_ERROR',
  IMPORT_PATIENTS_START: 'session/IMPORT_PATIENTS_START',
  IMPORT_PATIENTS_SUCCESS: 'session/IMPORT_PATIENTS_SUCCESS',
  IMPORT_PATIENTS_ERROR: 'session/IMPORT_PATIENTS_ERROR',
};

export default Types;
