import Types from './Types';
import { combineReducers } from 'redux';

const ordersReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.FETCH_SENT_ORDERS_SUCCESS:
    case Types.FETCH_EXPIRED_ORDERS_SUCCESS:
    case Types.FETCH_PENDING_ORDERS_PHARMACY_SUCCESS:
      return payload.orders;
    case Types.EDIT_ORDER_SUCCESS:
      return state.map(or =>
        or._id === payload.order._id
          ? {
              ...or,
              notes: payload.order.notes,
              products: payload.order.products,
            }
          : or,
      );
    case Types.ADD_NOTE_SUCCESS:
      return state.map(or =>
        or._id === payload.orderId
          ? {
              ...or,
              notes: [...or.notes, payload.note],
            }
          : or,
      );
    case Types.DELETE_NOTE_SUCCESS:
      return state.map(or =>
        or._id === payload.orderId
          ? {
              ...or,
              notes: payload.notes,
            }
          : or,
      );

    case Types.ADD_NOTE_PATIENT_SUCCESS:
      return state.map(or =>
        or.patient._id === payload.patientId
          ? {
              ...or,
              patient: {
                ...or.patient,
                notes: [...or.patient.notes, payload.note],
              },
            }
          : or,
      );
    case Types.DELETE_NOTE_PATIENT_SUCCESS:
      return state.map(or =>
        or.patient._id === payload.patientId
          ? {
              ...or,
              patient: {
                ...or.patient,
                notes: payload.notes,
              },
            }
          : or,
      );

    case Types.CLEAR_ORDERS:
      return null;
    default:
      return state;
  }
};

const pendingOrdersReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.FETCH_PENDING_ORDERS_SUCCESS:
      return payload.orders;
    case Types.SET_PENDING_DONE_SUCCESS:
      const orderToFind = state.map(or => or.pharmacy.id === payload.id);
      orderToFind.orders.map(order => {
        const date = new Date();
        return order.products.map(
          prod =>
            (prod.datePickedUp = new Date(
              date.getTime() - date.getTimezoneOffset() * 60000,
            ).toISOString()),
        );
      });
      return state.map(or =>
        or.pharmacy.id === payload.id ? orderToFind : or,
      );
    case Types.CLEAR_ORDERS:
      return null;
    default:
      return state;
  }
};

const loadingReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Types.FETCH_SENT_ORDERS_START:
    case Types.FETCH_EXPIRED_ORDERS_START:
    case Types.FETCH_PENDING_ORDERS_START:
    case Types.FETCH_PENDING_ORDERS_PHARMACY_START:
    case Types.SET_PENDING_DONE_START:
      return true;
    case Types.FETCH_SENT_ORDERS_SUCCESS:
    case Types.FETCH_SENT_ORDERS_ERROR:
    case Types.FETCH_EXPIRED_ORDERS_SUCCESS:
    case Types.FETCH_EXPIRED_ORDERS_ERROR:
    case Types.FETCH_PENDING_ORDERS_SUCCESS:
    case Types.FETCH_PENDING_ORDERS_ERROR:
    case Types.FETCH_PENDING_ORDERS_PHARMACY_SUCCESS:
    case Types.FETCH_PENDING_ORDERS_PHARMACY_ERROR:
    case Types.SET_PENDING_DONE_SUCCESS:
    case Types.SET_PENDING_DONE_ERROR:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.FETCH_SENT_ORDERS_START:
    case Types.FETCH_EXPIRED_ORDERS_START:
    case Types.FETCH_PENDING_ORDERS_START:
    case Types.FETCH_PENDING_ORDERS_PHARMACY_START:
    case Types.SET_PENDING_DONE_START:
    case Types.ADD_NOTE_START:
    case Types.DELETE_NOTE_START:
    case Types.ADD_NOTE_PATIENT_START:
    case Types.DELETE_NOTE_PATIENT_START:
      return null;
    case Types.FETCH_SENT_ORDERS_ERROR:
    case Types.FETCH_EXPIRED_ORDERS_ERROR:
    case Types.FETCH_PENDING_ORDERS_ERROR:
    case Types.FETCH_PENDING_ORDERS_PHARMACY_ERROR:
    case Types.SET_PENDING_DONE_ERROR:
    case Types.ADD_NOTE_ERROR:
    case Types.ADD_NOTE_PATIENT_ERROR:
    case Types.DELETE_NOTE_ERROR:
    case Types.DELETE_NOTE_PATIENT_ERROR:
      return payload.error;
    default:
      return state;
  }
};

export default combineReducers({
  pendingOrders: pendingOrdersReducer,
  orders: ordersReducer,
  loading: loadingReducer,
  error: errorReducer,
});
