const Types = {
  FETCH_PATIENT_START: 'patient/FETCH_PATIENT_START',
  FETCH_PATIENT_SUCCESS: 'patient/FETCH_PATIENT_SUCCESS',
  FETCH_PATIENT_ERROR: 'patient/FETCH_PATIENT_ERROR',

  POST_PATIENT_START: 'patient/POST_PATIENT_START',
  POST_PATIENT_SUCCESS: 'patient/POST_PATIENT_SUCCESS',
  POST_PATIENT_ERROR: 'patient/POST_PATIENT_ERROR',

  EDIT_PATIENT_START: 'patient/EDIT_PATIENT_START',
  EDIT_PATIENT_SUCCESS: 'patient/EDIT_PATIENT_SUCCESS',
  EDIT_PATIENT_ERROR: 'patient/EDIT_PATIENT_ERROR',

  DELETE_PATIENT_START: 'patient/DELETE_PATIENT_START',
  DELETE_PATIENT_SUCCESS: 'patient/DELETE_PATIENT_SUCCESS',
  DELETE_PATIENT_ERROR: 'patient/DELETE_PATIENT_ERROR',
  CLEAR_PATIENT: 'patient/CLEAR_PATIENT',

  REFRESH_PATIENT_STATISTICS_START: 'patient/REFRESH_PATIENT_STATISTICS_START',
  REFRESH_PATIENT_STATISTICS_SUCCESS:
    'patient/REFRESH_PATIENT_STATISTICS_SUCCESS',
  REFRESH_PATIENT_STATISTICS_ERROR: 'patient/REFRESH_PATIENT_STATISTICS_ERROR',

  REFRESH_ALL_PATIENTS_STATISTICS_START:
    'patient/REFRESH_ALL_PATIENTS_STATISTICS_START',
  REFRESH_ALL_PATIENTS_STATISTICS_SUCCESS:
    'patient/REFRESH_ALL_PATIENTS_STATISTICS_SUCCESS',
  REFRESH_ALL_PATIENTS_STATISTICS_ERROR:
    'patient/REFRESH_ALL_PATIENTS_STATISTICS_ERROR',
  CLEAR_REFRESH_ALL_PATIENTS_STATISTICS:
    'patient/CLEAR_REFRESH_ALL_PATIENTS_STATISTICS',
};

export default Types;
