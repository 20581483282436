import Types from './Types';
import { combineReducers } from 'redux';

const userReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.AUTH_ME_SUCCESS:
    case Types.EDIT_USER_SUCCESS:
    case Types.REGISTER_SUCCESS:
      return payload.user;
    case Types.LOGOUT:
    case Types.CLEAR_USER:
      return null;
    default:
      return state;
  }
};

const todayStatusReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.FETCH_TODAY_STATUS_SUCCESS:
      return payload.todayStatus;
    default:
      return state;
  }
};

const emailReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.VALIDATE_TOKEN_SUCCESS:
      return payload.result.email;
    default:
      return state;
  }
};

const authenticatedReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Types.LOGIN_SUCCESS:
    case Types.REGISTER_SUCCESS:
    case Types.AUTH_ME_SUCCESS:
    case Types.EDIT_USER_SUCCESS:
      return true;
    case Types.LOGOUT_SUCCESS:
    case Types.AUTH_ME_ERROR:
    case Types.EDIT_USER_ERROR:
    case Types.REFRESH_USER_ERROR:
    case Types.LOGOUT:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.AUTH_ME_ERROR:
    case Types.EDIT_USER_ERROR:
    case Types.LOGIN_ERROR:
    case Types.REGISTER_ERROR:
    case Types.REFRESH_USER_ERROR:
    case Types.LOGOUT_ERROR:
    case Types.CREATE_PASSWORD_ERROR:
    case Types.VALIDATE_TOKEN_ERROR:
    case Types.RESET_PASSWORD_ERROR:
    case Types.FETCH_TODAY_STATUS_ERROR:
    case Types.IMPORT_PATIENTS_ERROR:
      return payload.error;
    case Types.AUTH_ME_SUCCESS:
    case Types.EDIT_USER_SUCCESS:
    case Types.LOGIN_SUCCESS:
    case Types.REGISTER_SUCCESS:
    case Types.REFRESH_USER_SUCCESS:
    case Types.LOGOUT_SUCCESS:
    case Types.CREATE_PASSWORD_SUCCESS:
    case Types.VALIDATE_TOKEN_SUCCESS:
    case Types.RESET_PASSWORD_SUCCESS:
    case Types.FETCH_TODAY_STATUS_SUCCESS:
    case Types.IMPORT_PATIENTS_SUCCESS:
      return null;
    default:
      return state;
  }
};

const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case Types.AUTH_ME_REQUEST:
      return true;
    case Types.AUTH_ME_SUCCESS:
    case Types.LOGIN_SUCCESS:
    case Types.REGISTER_SUCCESS:
    case Types.AUTH_ME_ERROR:
    case Types.RESET_LOADING:
      return false;
    default:
      return state;
  }
};

const loadingImportReducer = (state = false, action) => {
  switch (action.type) {
    case Types.IMPORT_PATIENTS_START:
      return true;
    case Types.IMPORT_PATIENTS_SUCCESS:
    case Types.IMPORT_PATIENTS_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  user: userReducer,
  todayStatus: todayStatusReducer,
  email: emailReducer,
  error: errorReducer,
  authenticated: authenticatedReducer,
  loading: loadingReducer,
  loadingImport: loadingImportReducer,
});
