import Types from './Types';

// VALIDATE TOKEN

export const validateTokenRequest = () => ({
  type: Types.VALIDATE_TOKEN_REQUEST,
});

export const validateTokenSuccess = result => ({
  type: Types.VALIDATE_TOKEN_SUCCESS,
  payload: { result },
});

export const validateTokenError = error => ({
  type: Types.VALIDATE_TOKEN_ERROR,
  payload: { error },
});

// REGISTER

export const registerRequest = () => ({
  type: Types.REGISTER_REQUEST,
});

export const registerSuccess = user => ({
  type: Types.REGISTER_SUCCESS,
  payload: { user },
});

export const registerError = error => ({
  type: Types.REGISTER_ERROR,
  payload: { error },
});

// AUTH ME

export const authMeRequest = () => ({
  type: Types.AUTH_ME_REQUEST,
});

export const authMeSuccess = user => ({
  type: Types.AUTH_ME_SUCCESS,
  payload: { user },
});

export const authMeError = error => ({
  type: Types.AUTH_ME_ERROR,
  payload: { error },
});

// EDIT USER

export const editUserRequest = () => ({
  type: Types.EDIT_USER_REQUEST,
});

export const editUserSuccess = user => ({
  type: Types.EDIT_USER_SUCCESS,
  payload: { user },
});

export const editUserError = error => ({
  type: Types.EDIT_USER_ERROR,
  payload: { error },
});

// FETCH TODAY STATUS

export const fetchTodayStatusStart = () => ({
  type: Types.FETCH_TODAY_STATUS_START,
});

export const fetchTodayStatusSuccess = todayStatus => ({
  type: Types.FETCH_TODAY_STATUS_SUCCESS,
  payload: {
    todayStatus,
  },
});

export const fetchTodayStatusError = error => ({
  type: Types.FETCH_TODAY_STATUS_ERROR,
  payload: {
    error,
  },
});

// IMPORT PATIENTS

export const importPatientsStart = () => ({
  type: Types.IMPORT_PATIENTS_START,
});

export const importPatientsSuccess = todayStatus => ({
  type: Types.IMPORT_PATIENTS_SUCCESS,
  payload: {
    todayStatus,
  },
});

export const importPatientsError = error => ({
  type: Types.IMPORT_PATIENTS_ERROR,
  payload: {
    error,
  },
});

// LOGOUT

export const logout = () => ({
  type: Types.LOGOUT,
});

// CLEAR USER

export const clearUser = () => ({
  type: Types.CLEAR_USER,
});
