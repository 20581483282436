import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Loader from './js/client/components/Loader/Loader';
import ProtectedRouteAdmin from './js/utils/ProtectedRouteAdmin';

import * as sessionOperations from './js/redux/session/sessionOperations';
import * as sessionActions from './js/redux/session/sessionActions';

const AsyncAppClient = lazy(() =>
  import('./js/client/AppClient' /* webpackChunkName: 'app-client'*/),
);

const AsyncAppAdmin = lazy(() =>
  import('./js/admin/AppAdmin' /* webpackChunkName: 'app-admin'*/),
);

const AppContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector(state => state.session.loading);
  const user = useSelector(state => state.session.user);

  useEffect(() => {
    if (!user) {
      dispatch(sessionOperations.authMe());
    }
    return () => dispatch(sessionActions.clearUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <Suspense fallback={<Loader />}>
          <Switch location={location}>
            <ProtectedRouteAdmin path="/admin">
              <AsyncAppAdmin />
            </ProtectedRouteAdmin>
            <Route path="/admin" component={AsyncAppAdmin} />
            <Route path="/" component={AsyncAppClient} />
          </Switch>
        </Suspense>
      )}
    </>
  );
};

export default AppContainer;
