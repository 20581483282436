import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRouteAdmin = ({ path, ...rest }) => {
  const user = useSelector(state => state.session.user);
  const location = useLocation();

  if (user && user.isAdmin) {
    return <Route {...rest} path={path} />;
  } else if (user && !user.isAdmin) {
    return (
      <Redirect
        {...rest}
        to={{
          pathname: '/',
          state: {
            from: location,
          },
        }}
      />
    );
  } else if (!user) {
    return (
      <Redirect
        {...rest}
        to={{
          pathname: '/login',
          state: {
            from: location,
          },
        }}
      />
    );
  }
};

export default ProtectedRouteAdmin;
