import Types from './Types';
import { combineReducers } from 'redux';

const clinicsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case Types.FETCH_CLINICS_SUCCESS:
      return payload.clinics;
    case Types.POST_CLINIC_SUCCESS:
      if (state.length !== 0) {
        return [...state, payload.clinic];
      } else {
        return [payload.clinic];
      }
    case Types.EDIT_CLINIC_SUCCESS:
      return state.map(cl =>
        cl._id === payload.clinic._id ? payload.clinic : cl,
      );
    case Types.DELETE_CLINIC_SUCCESS:
      return state.filter(clinic => clinic._id !== payload.id);
    default:
      return state;
  }
};

const clinicReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.FETCH_CLINIC_SUCCESS:
    case Types.POST_CLINIC_SUCCESS:
      // case Types.EDIT_CLINIC_SUCCESS:
      return payload.clinic;
    case Types.CLEAR_CLINIC:
      return null;
    default:
      return state;
  }
};

const successCreateReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Types.POST_CLINIC_SUCCESS:
      return true;
    case Types.CLEAR_CLINIC:
      return false;
    default:
      return state;
  }
};

const loadingReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Types.FETCH_CLINICS_START:
    case Types.FETCH_CLINIC_START:
    case Types.POST_CLINIC_START:
    case Types.EDIT_CLINIC_START:
      return true;
    case Types.FETCH_CLINICS_SUCCESS:
    case Types.FETCH_CLINIC_SUCCESS:
    case Types.POST_CLINIC_SUCCESS:
    case Types.EDIT_CLINIC_SUCCESS:
    case Types.FETCH_CLINICS_ERROR:
    case Types.FETCH_CLINIC_ERROR:
    case Types.POST_CLINIC_ERROR:
    case Types.EDIT_CLINIC_ERROR:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Types.FETCH_CLINICS_START:
    case Types.FETCH_CLINIC_START:
    case Types.POST_CLINIC_START:
    case Types.EDIT_CLINIC_START:
    case Types.DELETE_CLINIC_START:
      return null;
    case Types.FETCH_CLINICS_ERROR:
    case Types.FETCH_CLINIC_ERROR:
    case Types.POST_CLINIC_ERROR:
    case Types.EDIT_CLINIC_ERROR:
    case Types.DELETE_CLINIC_ERROR:
      return payload.error;
    default:
      return state;
  }
};

export default combineReducers({
  clinics: clinicsReducer,
  clinic: clinicReducer,
  loading: loadingReducer,
  error: errorReducer,
  successCreate: successCreateReducer,
});
